/*
 * Override date picker styles
 */

.DayPicker {
  font-size: 13px;
  outline: none;
}

.DayPicker-Caption > div {
  font-size: 12px;
  text-align: center;
}

.DayPicker-Day {
  background: #FFF;
  border-radius: 0 !important;
  border: 1px solid #EEE;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background: #00AAE4;
  color: #FFF;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background: #00AAE4;
}

.DayPicker-Day.DayPicker-Day--disabled, .DayPicker-Day.DayPicker-Day--beforeSelectableRange {
  background: #F5F5F5;
  color: #dce0e0;
  cursor: not-allowed;
}

.DayPicker-Day.DayPicker-Day--activeRangeEnd:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):not(.DayPicker-Day--selected) {
  background: #F0FBFF;
}

.DayPicker-Day.DayPicker-Day--afterActiveRange {
  background: #FFF;
  color: #dce0e0;
  cursor: not-allowed;
}
.DayPicker-Day--hoverRange:not(.DayPicker-Day--outside):not(.DayPicker-Day--selected) {
  background: #F0FBFF !important;
}
